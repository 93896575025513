import './solutionfinder.scss';
import axios from 'axios';
import { gsap } from 'gsap';
import { createElementFromHTML } from './../../utilities/js/helper';

class Solutionfinder {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-solutionfinder',
            startNowButton: 'start-now',
            firstQuestionsURL: 'data-solutionfinder-ajax',
            questions: 'questions',
            questionContent: 'content',
            current: 'current',
            total: 'total',
            progressbarInner: 'progressbar-inner',
            progressbarOuter: 'progressbar-outer',
            arrowBack: 'arrow-back',
            inner: 'inner',
            intro: 'intro',
            answer: 'answer',
            recommendation: 'recommendation',
            startOver: 'start-over',
            onAjaxLoaded: null,
            onClosed: null,
            loader: null,
            shapeIcon: 'shape-icon'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$modalSolutionfinder = document.getElementById('solutionfinder');
        this.$solutionfinder = element;
        this.$startNow = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.startNowButton}"]`);
        this.$firstQuestionsURL = this.$solutionfinder.querySelector(`[${this.settings.firstQuestionsURL}]`).getAttribute(this.settings.firstQuestionsURL);
        this.$questions = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.questions}"]`);
        this.$questionContent = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.questionContent}"]`);
        this.$current = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.current}"]`);
        this.$total = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.total}"]`);
        this.$progressbarInner = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.progressbarInner}"]`);
        this.$progressbarOuter = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.progressbarOuter}"]`);
        this.$inner = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.inner}"]`);
        this.$closeButton = this.$modalSolutionfinder.querySelector('[data-modal="close-button"]');
        this.$arrowBack = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.arrowBack}"]`);
        this.$$answers = null;
        this.$startOverButton = null;
        this.$result = null;
        this.$shapeIcon = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.shapeIcon}"]`);
        this.$intro = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.intro}"]`);
        this.$skipIntro = this.$solutionfinder.querySelector('[data-solutionfinder-skip-intro]');
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        if (!this.$stepCounter < 2) {
            if (this.$skipIntro) {
                this.startpointQuestions();
            } else {
                this.startpointIntro();
            }
        } else {
            this.showRecommendation();
        }
    }

    setProgressState (current, total) {
        this.$current.innerHTML = current + 1;
        this.$total.innerHTML = total;

        gsap.to(this.$progressbarInner, {
            width: this.$progressbarOuter.clientWidth / total * (current + 1),
            duration: 0.5,
            onComplete: () => {
                // console.log('progress animation done');
            }
        });
    }

    startpointIntro () {
        this.$startNow.addEventListener('click', () => {
            this.$questions.classList.remove('is--hidden');
            this.slideToQuestions();
            this.sendRequest(this.$firstQuestionsURL);
        });
    }

    startpointQuestions () {
        this.slideToQuestions();
        this.sendRequest(this.$firstQuestionsURL);
    }

    slideToQuestions () {
        this.$closeButton.classList.add('is--hidden');
        gsap.to(this.$inner, {
            marginLeft: -(this.$solutionfinder.clientWidth),
            duration: 0.5,
            onComplete: () => {
                this.$closeButton.classList.remove('is--hidden');
                this.goBack();
            }
        });
    }

    clickAnswer () {
        this.$$answers = this.$solutionfinder.querySelectorAll(`[${this.settings.initAttr}="${this.settings.answer}"]`);

        for (let i = 0; i < this.$$answers.length; i++) {
            const answer = this.$$answers[i];
            answer.addEventListener('click', (e) => {
                this.sendRequest(this.$firstQuestionsURL, answer.getAttribute('data-solutionfinder-id'));
            });
        }
    }

    sendRequest (url, answerID) {
        this.$questionContent.innerHtml = '';
        axios({
            method: 'get',
            url: url, // eslint-disable-line
            params: { id: answerID },
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
        })
            .then((result) => {
                if (result) {
                    const $result = createElementFromHTML(result.data.content);
                    this.$questionContent.innerHTML = '';

                    if (!result.data.result) {
                        this.$questionContent.append($result);
                    } else {
                        this.$inner.append($result);
                        this.showRecommendation();
                    }

                    this.$stepCounter = result.data.currentStep + 1;

                    if (!result.data.result && result.data.icon !== null) {
                        this.$shapeIcon.src = result.data.icon;
                    }

                    this.setProgressState(result.data.currentStep, result.data.totalSteps);
                    this.clickAnswer();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    showRecommendation () {
        this.$modalSolutionfinder.classList.add('has--solution');
        this.$questions.classList.add('is--hidden');
        this.$intro.classList.add('is--hidden');
        this.$solutionfinder.scrollTop = 0;
        this.startOver();
    }

    goBack () {
        this.$arrowBack.addEventListener('click', () => {
            if (this.$stepCounter === 1) {
                this.$closeButton.classList.add('is--hidden');
                gsap.to(this.$inner, {
                    marginLeft: 0,
                    duration: 0.5,
                    onComplete: () => {
                        this.$closeButton.classList.remove('is--hidden');
                        this.startpointIntro();
                    }
                });
                return false;
            } else {
                this.sendRequest(this.$firstQuestionsURL);
            }
        });
    }

    startOver () {
        this.$startOverButton = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.startOver}"]`);
        this.$result = this.$solutionfinder.querySelector(`[${this.settings.initAttr}="${this.settings.recommendation}"]`);

        this.$startOverButton.addEventListener('click', (e) => {
            e.preventDefault();
            this.$result.remove();
            this.$modalSolutionfinder.classList.remove('has--solution');
            this.$questions.classList.remove('is--hidden');
            this.$intro.classList.remove('is--hidden');
            this.sendRequest(this.$firstQuestionsURL);
        });
    }
}

export { Solutionfinder };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$solutionfinder = $context.querySelectorAll('[data-solutionfinder="root"]');

        for (let i = 0; i < $$solutionfinder.length; i++) {
            const $solutionfinder = new Solutionfinder($$solutionfinder[i]);
            $solutionfinder.initialize();
        }
    }
});
